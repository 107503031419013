<!--
 * @Description: 账单草稿
 * @Author: 琢磨先生
 * @Date: 2022-11-17 10:37:33
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-12-07 12:13:34
-->



<template>
  <el-card class="box">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="房间">
        <el-cascader
          :options="huxing_list"
          v-model="query.storeIds"
          clearable
          :props="{ multiple: true }"
          collapse-tags
          collapse-tags-tooltip
          filterable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="月份">
        <el-date-picker
          v-model="query.month"
          type="month"
          format="YYYY年MM月"
          value-format="YYYY-MM-01"
          placeholder
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <bill-create @close="onSearch" :huxing_list="huxing_list"></bill-create>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData" border :row-style="handleRowStyle">
      <el-table-column label="账期"  width="120">
        <template #default="scope">
          <div>{{ scope.row.month_text }}</div>
        </template>
      </el-table-column>
      <el-table-column label="房东" width="140">
        <template #default="scope">
          <div>{{ scope.row.landlord.name }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="房号"
        width="140"
        prop="house_no"
      ></el-table-column>
      <el-table-column label="营业总额(元)" width="140">
        <template #default="scope">
          <span v-if="scope.row.is_rebuild">--</span>
          <span v-else>￥{{ scope.row.decimal_total_amount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="业主分成(元)" width="140">
        <template #default="scope">
          <span v-if="scope.row.is_rebuild">--</span>
          <div v-else>
            <div>{{ scope.row.decimal_income_rate }}%</div>
            <div>￥{{ scope.row.decimal_landlord_income_amount }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="成本(元)" width="120">
        <template #default="scope">
          <span v-if="scope.row.is_rebuild">--</span>
          <div v-else>￥{{ scope.row.decimal_expense_total_amount }}</div>
        </template>
      </el-table-column>
      <el-table-column label="实际成本(元)" width="120">
        <template #default="scope">
          <span v-if="scope.row.is_rebuild">--</span>
          <div v-else>
            ￥{{ scope.row.decimal_actual_expense_total_amount }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="业主成本(元)" width="120">
        <template #default="scope">{{
          scope.row.is_rebuild
            ? "--"
            : `￥${scope.row.decimal_landlord_expense_amount}`
        }}</template>
      </el-table-column>
      <el-table-column label="业主净收益(元)" width="120">
        <template #default="scope">{{
          scope.row.is_rebuild
            ? "--"
            : `￥${scope.row.decimal_landlord_profit_amount}`
        }}</template>
      </el-table-column>
      <el-table-column label="商户成本" width="120">
        <template #default="scope">{{
          scope.row.is_rebuild
            ? "--"
            : `￥${scope.row.decimal_business_expense_amount}`
        }}</template>
      </el-table-column>
      <el-table-column label="商户实际成本" width="120">
        <template #default="scope">
          {{
            scope.row.is_rebuild
              ? "--"
              : `￥${scope.row.decimal_business_actual_expense_amount}`
          }}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="120">
        <template #default="scope">
          <span v-if="scope.row.is_rebuild">账单生成中</span>
          <el-tag v-else type="info" size="small">{{
            scope.row.status_text
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="备注" min-width="280">
        <template #default="scope">
          <div>{{ scope.row.error_remark }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        prop="create_at"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="120">
        <template #default="scope">
          <el-button type="primary" link @click="onDtl(scope.row)" v-if="is_can_dtl"
            >详情</el-button
          >
          <!-- <el-popconfirm
              title="确定要开放确认？"
              @confirm="onOpenConfirm(scope.row)"
              v-if="
                scope.row.status != 20 &&
                scope.row.status != 30 &&
                scope.row.status != 40
              "
            >
              <template #reference>
                <el-button type="primary" link size="small" class="text-danger"
                  >开放确认</el-button
                >
              </template>
          </el-popconfirm>-->
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <bill-dtl
    :id="current_id"
    @close="current_id = null"
    @success="this.loadData()"
  ></bill-dtl>
</template>
  
  <script>
import BillCreate from "./create.vue";
import BillDtl from "./dtl.vue";
import common_api from "@/http/common_api";
import huxing_api from "@/http/huxing_api";

export default {
  components: {
    BillDtl,
    BillCreate,
  },
  data() {
    return {
      loading: false,
      //数据
      tableData: [],
      query: {
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      //
      enums: {},
      //房型options
      huxing_list: [],
      //当前id
      current_id: "",
      is_can_dtl:false,
    };
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
    huxing_api.get_stores().then((res) => {
      if (res.code == 0) {
        this.huxing_list = [];
        res.data.forEach((x) => {
          var model = {
            value: x.id,
            label: x.name,
            children: [],
          };
          x.store_list.forEach((item) => {
            model.children.push({
              value: item.id,
              label: item.house_no,
              is_stop :item.is_stop,
            });
          });
          this.huxing_list.push(model);
        });
      }
    });
    this.loadData();
    this.is_can_dtl =this.$power('seller/v1/landlordbill/dtl');

  },
  methods: {
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },

    /**
     * 加载数据
     */
    loadData() {
      // this.query.store_id = "";
      var arg = Object.assign({}, this.query);
      delete arg.storeIds;
      if (
        Array.isArray(this.query.storeIds) &&
        this.query.storeIds.length > 0
      ) {
        arg.store_ids = this.query.storeIds.map((x) => {
          return x[1];
        });
      }
      this.loading = true;
      this.$http
        .post("seller/v1/landlordBill/draft", arg)
        .then((res) => {
          this.loading = false;
          if (res.code == 0) {
            this.tableData = res.data;
          }
        });
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },

    /**
     *开放确认
     */
    onOpenConfirm(item) {
      this.$http
        .get("seller/v1/landlordBill/openConfirm?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.loadData();
          }
        });
    },

    /**
     * 查看详情
     */
    onDtl(item) {
      this.current_id = item.id;
    },

    handleRowStyle(o) {
      if (o.row.is_rebuild) {
        return "color:#409EFF;";
      }
    },
  },
};
</script>
  
  <style   scoped>
</style>